'use client';

import { QUERY_KEY_GET_DOWNLOADS } from '@/constants';

import { getDownloads } from '@/utils';
import { useQuery } from '@tanstack/react-query';

export interface UseDownloadsOptions {
  id: string;
}

export function useDownloads(options: UseDownloadsOptions) {
  const { id } = options;

  return useQuery<number>({
    queryKey: [...QUERY_KEY_GET_DOWNLOADS, id],
    queryFn: async () => getDownloads(id),
  });
}
