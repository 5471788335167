'use client';

import type { ChangeEventHandler } from 'react';
import clsx from 'clsx';
import { useCallback, useState } from 'react';

import { RangeInput } from './ui';

export function RangeInputDemo() {
  const [value, setValue] = useState(20);
  const onChangeHandler: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      setValue(Number(event.target.value));
    },
    [],
  );

  return (
    <div
      className={clsx(
        'relative flex aspect-video items-center justify-center rounded-t-[inherit] bg-orange-50 p-6 px-12 dark:bg-neutral-800/90',
        'after:pointer-events-none after:absolute after:inset-0 after:z-10 after:rounded-[inherit] after:border after:border-black/10 dark:after:border-neutral-300/5 dark:after:border-b-neutral-400/5',
        'before:pointer-events-none before:absolute before:inset-px before:z-10 before:rounded-[inherit] before:shadow-light-top before:shadow-white/30 dark:before:hidden',
      )}
    >
      {/* <div
        className="absolute z-0 inset-0 rounded-[inherit] bg-linear-to-br from-cyan-500 to-blue-500"
        aria-hidden="true"
        // Calculate the opacity based on the value of the range input based on how far it is from 25
        // style={{ opacity: Math.abs(value - 25) / 100 }}
      /> */}

      {/* <div
        className="absolute inset-0 z-0 rounded-[inherit] bg-linear-to-br from-orange-400 to-orange-500"
        aria-hidden="true"
        // Calculate the opacity based on the value of the range input based on how far it is from 50
        style={{ opacity: value / 100 }}
    /> */}
      {/* <div
        className="absolute z-0 inset-0 rounded-[inherit] bg-linear-to-br from-violet-500 to-fuchsia-500"
        aria-hidden="true"
        // Calculate the opacity based on the value of the range input based on how far it is from 75
        // style={{ opacity: Math.abs(value - 75) / 100 }}
      />
      <div
        className="absolute z-0 inset-0 rounded-[inherit] bg-linear-to-br from-purple-500 to-pink-500"
        aria-hidden="true"
        // style={{ opacity: value / 100 }}
    /> */}
      <div className="relative z-10 w-full">
        <RangeInput
          value={value}
          onChange={onChangeHandler}
          min={7.5}
          max={100}
          className="text-orange-500"
        />
      </div>
    </div>
  );
}
