'use client';

import type { PropsWithChildren, ReactNode } from 'react';
import { useEffect, useState } from 'react';

export function ClientOnly({ children }: PropsWithChildren): ReactNode {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    requestAnimationFrame(() => setIsMounted(true));
  }, []);

  return isMounted ? children : null;
}
