'use client';

import type { GetRepoResponse } from '@/utils';

import { QUERY_KEY_GET_REPO } from '@/constants';
import { getRepo } from '@/utils';
import { useQuery } from '@tanstack/react-query';

export interface UseRepoOptions {
  id: string;
}

export function useRepo(options: UseRepoOptions) {
  const { id } = options;

  return useQuery<GetRepoResponse>({
    queryKey: [...QUERY_KEY_GET_REPO, id],
    queryFn: async () => getRepo(id),
  });
}
