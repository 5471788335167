'use client';

import type { Project } from '@/types';
import { useRepo } from '@/hooks';
import { isString } from 'lodash-es';
import { ChevronRight } from 'lucide-react';
import { useMemo } from 'react';
import { CardStatistics } from './CardStatistics';
import { RangeInputDemo } from './RangeInputDemo';
import { Video } from './ui';

export interface ProjectCardProps extends Project {
  id: string;
}

const toSentence = (input: string) => !input.endsWith('.') ? `${input}.` : input;

export function ProjectCard(props: ProjectCardProps) {
  const { id, name, media, description: _description } = props;
  const { data } = useRepo({ id });

  const description = useMemo(() => {
    if (isString(_description)) {
      return _description;
    }
    return isString(data?.description) ? toSentence(data.description) : '';
  }, [_description, data?.description]);

  return (
    <article
      className="not-prose flex flex-col rounded-xl bg-white shadow-sm shadow-neutral-500/15 ring-1 ring-neutral-500/10 transition-all dark:bg-zinc-900 dark:shadow-black/60 dark:ring-zinc-300/10"
    >
      <figure className="-mx-px -mt-px rounded-t-[inherit]">
        {
          media?.type === 'video' && (
            <Video
              src={media.src}
              poster={media.poster}
              className="rounded-t-[inherit]"
              thumbnails={media.thumbnails}
            />
          )
        }

        {
          media?.type === 'image' && (
            // eslint-disable-next-line next/no-img-element
            <img
              src={media.src}
              alt={media.alt ?? ''}
              className="aspect-video rounded-t-[inherit] object-cover"
            />
          )
        }

        {!media && <RangeInputDemo />}
      </figure>

      <div
        className="flex grow flex-col justify-between px-4 py-3"
      >
        <h3 className="font-normal">{name}</h3>

        {
          Boolean(description.length) && (
            <p className="mb-2.5 mt-1 text-sm text-subtle">
              {description}
            </p>
          )
        }

        <div className="flex items-center text-sm">
          <CardStatistics id={id} />

          <a href={`https://github.com/sampotts/${id}`} target="_blank" className="group ml-auto inline-flex items-center gap-px text-orange-400">
            View
            <ChevronRight strokeWidth={1.5} absoluteStrokeWidth size={18} className="relative transition-all group-hocus:-right-px" />
          </a>
        </div>
      </div>
    </article>
  );
}
