import type { VariantProps } from 'class-variance-authority';
import type { ComponentPropsWithRef } from 'react';

import { cn } from '@/utils';
import { buttonVariants } from './buttonVariants';

export interface ButtonProps
  extends ComponentPropsWithRef<'button'>,
  VariantProps<typeof buttonVariants> {
}

export function Button({ ref, className, variant, size, ...props }: ButtonProps) {
  return (
    <button
      type="button"
      className={cn(buttonVariants({ variant, size, className }))}
      ref={ref}
      {...props}
    />
  );
}

Button.displayName = 'Button';
