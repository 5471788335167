'use client';

import { useTheme } from '@/providers';
import { MoonIcon, SunIcon } from 'lucide-react';

import { useCallback } from 'react';

import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from './ui';

type ColorScheme = 'light' | 'dark' | 'system';

export function ColorSchemeDropdownMenu() {
  const { theme, setTheme } = useTheme();

  const onChangeHandler = useCallback((scheme: string) => {
    setTheme(scheme as ColorScheme);
  }, [setTheme]);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="icon">
          <SunIcon className="rotate-0 scale-100 transition-transform dark:-rotate-90 dark:scale-0" />
          <MoonIcon className="absolute rotate-90 scale-0 transition-transform dark:rotate-0 dark:scale-100" />
          <span className="sr-only">Toggle theme</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" side="top">
        <DropdownMenuLabel>Appearance</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuRadioGroup
          value={theme}
          onValueChange={onChangeHandler}
        >
          <DropdownMenuRadioItem value="light" data-color-scheme="light">
            Light
          </DropdownMenuRadioItem>
          <DropdownMenuRadioItem value="dark" data-color-scheme="dark">
            Dark
          </DropdownMenuRadioItem>
          <DropdownMenuRadioItem value="system" data-color-scheme="system">
            System
          </DropdownMenuRadioItem>
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
