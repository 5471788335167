'use client';

import type { ComponentProps } from 'react';
import { ThemeProvider as ThemeProviderPrimitive } from 'next-themes';

export function ThemeProvider(props: ComponentProps<typeof ThemeProviderPrimitive>) {
  const { children, attribute = 'class', storageKey = 'color-scheme' } = props;

  return (
    <ThemeProviderPrimitive attribute={attribute} storageKey={storageKey}>
      {children}
    </ThemeProviderPrimitive>
  );
}
