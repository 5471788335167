/* eslint-disable ts/no-unsafe-return */
import type { NumberFlowProps } from '@number-flow/react';

import { useDownloads, useRepo, useRequests } from '@/hooks';
import NumberFlow from '@number-flow/react';

import clsx from 'clsx';

/* eslint-disable ts/no-unsafe-assignment */
import { useMemo } from 'react';
import { ChartIcon, DownloadIcon, StarIcon } from './Icon';

export interface CardStatisticsProps {
  id: string;
}

type StatisticType = 'stars' | 'downloads' | 'requests';

interface DisplayValueProps {
  type: StatisticType;
  value: number;
  isLoading: boolean;
};

function getLabel(key: StatisticType) {
  switch (key) {
    case 'stars':
      return 'Stars on GitHub';
    case 'downloads':
      return 'Downloads via npm';
    case 'requests':
      return 'Requests per second';
    default:
      return key;
  }
}

function DisplayValue(props: DisplayValueProps) {
  const { type, value, isLoading } = props;

  const Icon = useMemo(() => {
    switch (type) {
      case 'stars':
        return StarIcon;
      case 'downloads':
        return DownloadIcon;
      case 'requests':
        return ChartIcon;
    }
  }, [type]);

  const format: NumberFlowProps['format'] | undefined = useMemo(() => value > 9999
    ? {
        notation: 'compact',
        compactDisplay: 'short',
        maximumFractionDigits: 1,
      }
    : undefined, [value]);

  return (
    <div
      key={type}
      className="flex items-center gap-1.5"
      title={getLabel(type)}
    >
      <dt>
        <Icon />
        <span className="sr-only">{getLabel(type)}</span>
      </dt>
      <dd className={clsx('relative flex items-center', { 'text-transparent min-w-8 after:absolute after:inset-x-0 after:inset-y-0.5 after:rounded-sm after:bg-neutral-100 dark:after:bg-neutral-800 after:animate-pulse': isLoading })}>
        <NumberFlow value={value} format={format} className="tabular-nums" />
      </dd>
    </div>
  );
}

function Stars({ id }: { id: string }) {
  const { data, isLoading } = useRepo({ id });
  return <DisplayValue type="stars" value={data?.stargazers_count ?? 0} isLoading={isLoading} />;
}
function Downloads({ id }: { id: string }) {
  const { data, isLoading } = useDownloads({ id });
  return <DisplayValue type="downloads" value={data ?? 0} isLoading={isLoading} />;
}
function Requests({ id }: { id: string }) {
  const { data, isLoading } = useRequests({ id });
  return <DisplayValue type="requests" value={data ?? 0} isLoading={isLoading} />;
}

export function CardStatistics(props: CardStatisticsProps) {
  const { id } = props;

  return (
    <dl className="flex items-center gap-2.5 text-subtlest">
      <Stars id={id} />
      <Downloads id={id} />
      <Requests id={id} />
    </dl>
  );
}
