'use client';

import { QUERY_KEY_GET_REQUESTS } from '@/constants';

import { getRequests } from '@/utils';
import { useQuery } from '@tanstack/react-query';

export interface UseRequestsOptions {
  id: string;
}

export function useRequests(options: UseRequestsOptions) {
  const { id } = options;

  return useQuery<number>({
    queryKey: [...QUERY_KEY_GET_REQUESTS, id],
    queryFn: async () => getRequests(id),
  });
}
